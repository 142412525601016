import React, { useState } from 'react';
import './home.css';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
// import Axios from 'axios';

import logosPepsi from '../../assets/img/logo-pepsi.svg'
import logos from '../../assets/img/logos-header.png'
import team from '../../assets/img/team-header.svg'
import champions from '../../assets/img/champions.svg'
import messi from '../../assets/img/messi.png'

import products from '../../assets/img/Products.png'
import step1 from '../../assets/img/step1.png'
import step2 from '../../assets/img/step2.png'
import step3 from '../../assets/img/step3.png'
import logosForm from '../../assets/img/logos-form2.png'
import upload from '../../assets/img/upload.svg'

import { CheckCircle, Circle } from '@mui/icons-material';
import { register } from '../../api/api';
import Terms from '../../components/terms';
import Alert from '../../components/alert';

function Home() {
    const ALPHA__REGEX = /^[a-zA-Z\s]+$/;
    var invalidChars_phone = [
        "-",
        "q","w","r","t","y","u","i","o","p",
        "a","s","d","f","g","h","j","k","l","z",
        "x","c","v","b","n","m",
        "Q","W","E","R","T","Y","U","I","O","P",
        "A","S","D","F","G","H","J","K","L","Z",
        "X","C","V","B","N","M",
        "e",
        ".",
        "[",
        "]",
        "{",
        "}",
        "=",
        "/",
        ",",
        "<",
        ">",
        "'",
        "?",
        ":",
        ";"
    ];
    var invalidChars = [
        "-",
        "q","w","r","t","y","u","i","o","p",
        "a","s","d","f","g","h","j","k","l","z",
        "x","c","v","b","n","m",
        "Q","W","E","R","T","Y","U","I","O","P",
        "A","S","D","F","G","H","J","K","L","Z",
        "X","C","V","B","N","M",
        "+",
        "e",
        ".",
        "[",
        "]",
        "{",
        "}",
        "=",
        "/",
        ",",
        "<",
        ">",
        "'",
        "?",
        ":",
        ";"
    ];
    const [full_name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [check, setCheck] = useState(false);
    const [image, setImage] = useState('');
    const [base64Image, setBase64Image] = useState('');
    const [ci, setCI] = useState('');
    const [imageName, setImageName] = useState('');
    const [terms, setTerms] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [checkFields, setCheckFields] = useState(false);
    // const [emailError, setEmailError] = useState('Ingresa tu correo');

    function handleChange(event: any) {
        validEmptiesOnChange()
        if (event.target.id === 'ci') {
            setCI(event.target.value)
        }
        if (event.target.id === 'name') {
            setName(event.target.value)
        }
        if (event.target.id === 'email') {
            if (!isValidEmail(event.target.value)) {
                setErrorEmail('Email inválido');
              } else {
                setErrorEmail('');
              }
            setEmail(event.target.value)
        }
        if (event.target.id === 'check') {
            setCheck(event.target.checked)
        }
        if (event.target.id === 'phone') {
            
            console.log(event.target.value)
            setNumber(event.target.value)
        }
        if (event.target.id === 'photo') {
            const file = event.target.files[0];

            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64 = reader.result;
                    if (base64) {
                        let _base64 = base64.toString()
                        let str_base64 = _base64.split(",")
                        setBase64Image(str_base64[1])
                    }
                };
                if (event.target.files[0]) {
                    setImage(event.target.files[0]);
                    setImageName(event.target.files[0].name)
                }
            }
        }
        
    }
    const handleSubmit = async (event: any) => {
        if (await validateEmpties()) {
            setError("")
            setCheckFields(false)
            if (check) {
                let data = await register(email, full_name, ci, number, imageName, base64Image)
                if (data) {
                    setShowAlert(true);
                }
                setName('');
                setEmail('');
                setNumber('');
                setImageName('');
                setImage('');
                setCI('');
                setCheck(!check);

            }
            // } 
            else {
                //setEmailError("El correo no es válido");
                setError("Debes aceptar el uso de tus datos personales para registrarte.");
            }            
        }
        else {
            setCheckFields(true)
            setError("Debe llenar todos los campos")
        }
    }
    
    const validateEmpties = async () => {
        if (full_name !== '' && email !== '' && ci !== '' && number !== '' && imageName !== '' && base64Image !== '') {
            return true;
        }
        return false;
    }
    const validEmptiesOnChange = async () => {
        const empties = validateEmpties();
        if (await empties) {
            setError("")
        } else {
            setError("Debe llenar todos los campos")
        }
    }
    function isValidEmail(email: any) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function clearImg() {
        setImageName('');
        setImage('');
    }
    return (
        <div className='text-white overflow-x-hidden'>
            {/* HEADER */}
            <section className='h-auto md:h-screen w-screen bg-header-movil md:bg-header bg-cover bg-top'>
                <div className='max-w-screen-2xl w-full h-full grid grid-cols-12 px-10 md:px-20 m-auto'>
                    
                    <div className=' top-4 md:top-0 col-span-12 md:col-span-3 h-32 md:h-full flex flex-row md:flex-col justify-between md:justify-center items-center md:items-start md:gap-14 relative'>
                        <img className='w-12 md:w-24 relative md:absolute md:top-10' src={logosPepsi} alt="logosPepsi" />
                        <a href="#how" className='hidden md:flex text-2xl font-black italic hover:underline bg-gradient-blue rounded-full h-14 w-11/12 shadow justify-center items-center'>CÓMO PARTICIPAR</a>
                        <button className='hidden pl-10 md:block text-left text-2xl xl:text-[23px] font-black italic hover:underline bg-gradient-blue rounded-full min-h-10 w-11/12' onClick={() => setTerms(true)}>
                            <p className="">TÉRMINOS Y CONDICIONES</p>
                        </button>
                        <img className='w-16 md:w-36 relative md:absolute md:bottom-11 2xl:bottom-20' src={champions} alt="champions" />
                    </div>
                    <div className='col-span-12 top-14 md:top-0 md:col-span-6 h-full flex flex-col justify-center items-center gap-5 relative'>
                        <img className='w-[61%] md:w-96' src={logos} alt="logos" />
                        <img className='w-full' src={team} alt="team" />
                        <a href='#contact' className='text-3xl md:hover:text-4xl font-black italic bg-gradient-blue rounded-full h-14 md:h-16 w-full md:w-4/5 shadow flex justify-center items-center'>REGISTRA TUS DATOS</a>
                    </div>
                    <div className='col-span-12 md:col-span-3 h-full flex flex-row md:flex-col justify-center items-center relative min-h-[380px]'>
                        <div className='w-1/2 md:hidden flex flex-col justify-center gap-5 items-center'>
                            <a href="#how" className='text-base font-black italic hover:underline text-center leading-none bg-gradient-blue rounded-full w-full h-9 shadow py-2'>CÓMO PARTICIPAR</a>
                            <button className='text-base font-black italic hover:underline text-left leading-none bg-gradient-blue rounded-full h-11 w-full px-5' onClick={() => setTerms(true)} >TÉRMINOS Y CONDICIONES</button>
                        </div>
                        <img className=' w-[43%] 2xl:w-[200%] md:w-11/12 md:absolute translate-y-5 md:translate-y-0 bottom-0 right-0 md:right-10' src={messi} alt="messi" />
                    </div>
                </div>
            </section>
            <section className='w-screen bg-form bg-cover bg-top border-t-8 border-[#122A73] pt-5 md:pt-20 pb-10 flex flex-col justify-center items-center'>
                <div id='how' className='max-w-screen-2xl w-full h-full grid grid-cols-2 px-10 md:px-20 py-10 m-auto'>
                    <div className='col-span-2 md:col-span-1 h-full flex flex-col justify-center items-center md:items-start relative'>
                        <div className='w-5/6 text-center text-lg md:text-2xl font-black italic -rotate-[5deg] leading-tight'>Consigue esta promoción en los <span className='text-[#F4E300]'>establecimientos participantes</span> </div>
                        <img className=' w-[71%] md:w-3/5 mt-0 md:-mt-5 translate-x-0 md:translate-x-20 md:translate-y-3' src={logosForm} alt="logosForm" />
                        <img className='md:translate-x-0 translate-x-5' src={products} alt="products" />
                    </div>
                    <div className='col-span-2 md:col-span-1 h-full flex flex-col justify-center items-center gap-7 md:gap-5 relative'>
                        <img className=' w-11/12 md:-translate-x-16' src={step1} alt="step1" />
                        <img className=' w-11/12 md:w-[96%] -translate-x-3 md:sw-24 md:translate-x-10' src={step2} alt="step2" />
                        <img className=' w-10/12 md:w-[85%] -translate-x-6 md:translate-x-28' src={step3} alt="step3" />
                    </div>
                </div>
                <div id='contact' className='max-w-screen-2xl w-full h-auto md:h-full px-10 md:px-20 pt-10 md:pt-20 pb-10 relative flex flex-col justify-center items-center m-auto'>
                    <div className='w-full text-center md:text-left text-3xl md:text-6xl font-black italic -rotate-3 md:absolute md:translate-x-0 md:left-20 md:top-0'>REGÍSTRATE:</div>
                    <div className='w-full md:w-4/5 h-auto md:h-full mt-16 flex flex-col md:grid md:grid-cols-2 pb-0 gap-5 md:gap-10'>
                        <div className='md:col-span-1 h-full flex flex-col justify-start items-start relative'>
                            <div className='w-full gap-2 flex flex-col'>
                                <label htmlFor="name" className='text-white text-xl md:text-xl font-black italic text-center md:text-left px-6'>NOMBRE COMPLETO</label>
                                <TextField onChange={handleChange} value={full_name} id="name" type="text" placeholder="Nombre" variant="outlined" fullWidth size='small'
                                    error={checkFields && full_name === ''}
                                    helperText={checkFields && full_name === '' ? <p className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-left italic'>Ingresa tu nombre</p> : ''} 
                                    onKeyDown={(event) => {
                                        if (!ALPHA__REGEX.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                            </div>
                        </div>
                        <div className='md:col-span-1 h-full flex flex-col justify-start items-start relative'>
                            <div className='w-full gap-2 flex flex-col mt-5 md:mt-0'>
                                <label htmlFor="email" className='text-white text-xl md:text-xl font-black italic text-center md:text-left px-6'>CORREO</label>
                                <TextField type='email' value={email} onChange={handleChange} id="email" placeholder="Correo Electrónico" variant="outlined" fullWidth size='small'
                                    error={checkFields && email === ''}
                                    helperText={checkFields && email === '' ? <p className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-left italic'>Ingresa tu correo</p> : ''}
                                    
                                    
                                    />
                                <div className='text-base'><span className='text-[#D40000]'>{errorEmail}</span></div>

                                {/* helperText={checkFields && email === '' ? <p className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-left italic'>{emailError}</p> : ''} /> */}
                            </div>
                            
                        </div>
                    </div>
                    <div className='w-full md:w-4/5 h-auto md:h-full mt-8 md:mt-4 flex flex-col md:grid md:grid-cols-2 pb-0 gap-5 md:gap-10'>
                        <div className='md:col-span-1 h-full flex flex-col justify-start items-start relative'>
                            <div className='w-full gap-2 flex flex-col mt-4 md:mt-0'>
                                <label htmlFor="phone" className='text-white text-xl md:text-xl font-black italic text-center md:text-left px-6'>NÚMERO DE CONTACTO</label>
                                <TextField onChange={handleChange} value={number} id="phone" type="text" placeholder="Celular o Teléfono" variant="outlined" fullWidth size='small'
                                    error={checkFields && number === ''}
                                    helperText={checkFields && number === '' ? <p className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-left italic'>Ingresa tu teléfono</p> : ''}
                                    onKeyDown={(event) => {
                                        if (invalidChars_phone.includes(event.key)) {
                                            event.preventDefault();
                                        }
                                      }}
                                    />
                            </div>
                        </div>
                        <div className='md:col-span-1 h-full flex flex-col justify-start items-start relative'>
                        <div className='w-full gap-2 flex flex-col mt-5 md:mt-0'>
                                <label htmlFor="ci" className='text-white text-xl md:text-xl font-black italic text-center md:text-left px-6'>CÉDULA</label>
                                <TextField onChange={handleChange} id="ci" value={ci} type="text" placeholder="Número de cédula" variant="outlined" fullWidth size='small'
                                    error={checkFields && ci === ''}
                                    helperText={checkFields && ci === '' ? <p className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-left italic'>Ingresa tu cédula</p> : ''} 
                                    onKeyDown={(event) => {
                                        if (invalidChars.includes(event.key)) {
                                            event.preventDefault();
                                        }
                                      }}
                                      onWheel={(event) => 
                                         {
                                            event.preventDefault();
                                          }
                                    }
                                    />
                            </div>
                        </div>
                    </div>
                    <div className=' w-full md:w-2/5 gap-2 flex flex-col mt-5 pb-14'>
                                <label htmlFor="photo" className='text-white text-lg md:text-xl font-black italic md:text-left text-center md:translate-x-20 mt-7 md:mt-0'>SUBE LA FOTO DE TU FACTURA</label>
                                <div id='inputlabel' className='md:inputlabel flex justify-center items-center gap-6 md:gap-10'>
                                    <Button variant="contained" component="label" size="large">
                                        {
                                            imageName ?
                                                <p className='text-white text-sm italic truncate'>{imageName}</p>
                                                :
                                                <img className='h-6' src={upload} alt="upload" />
                                        }
                                        <input onChange={handleChange} id='photo' hidden accept="image/*,.pdf" multiple type="file" />
                                    </Button>
                                    <button onClick={() => { clearImg() }} className='text-xl'>CANCELAR</button>
                                </div>
                                {checkFields && imageName === '' ? <p className='mt-1 md:mt-0 text-sm md:px-16 px-10 font-bold w-full text-red-600 text-left italic'>Carga una foto</p> : ''}
                    </div>
                    <div className='w-full h-full flex flex-col justify-center items-center relative gap-5'>
                        <div className='ml-12'>
                            <FormControlLabel control={<Checkbox id='check' checked={check} onChange={handleChange} icon={<Circle sx={{ color: '#D9D9D9'}} />} checkedIcon={<CheckCircle sx={{ color: '#D9D9D9' }} />} />} label="ACEPTAS EL USO DE DATOS PERSONALES"/>
                        </div>
                        <div className='mt-1 md:mt-0 text-sm font-bold w-full text-red-600 text-center italic'>{error}</div>
                        <button onClick={handleSubmit} className='text-2xl md:text-3xl text-[#1E1E1E] font-black italic bg-gradient-gray rounded-full py-3 px-10 md:px-20'>ENVÍA TU REGISTRO</button>
                    </div>
                </div>
                <div className='w-2/3 h-full flex justify-center items-center px-20 py-10'>
                    {/* <a href="#" className='text-3xl font-black italic hover:underline'>REGLAMENTO</a> */}
                    <a href="https://www.instagram.com/pepsiecuador/" target="_blank" rel="noreferrer" className='text-3xl font-black italic hover:underline'>CONTACTO</a>
                    {/* <a href="#" className='text-3xl font-black italic hover:underline'>POLÍTICA DE PRIVACIDAD</a> */}
                </div>
                <p className='w-full h-full font-normal mt-5 pb-3 md:pb-10 px-10 md:px-20 text-[10px] md:text-xs text-center m-auto'>
                    Promoción exclusiva para mayores de 18 años. Válida en Guayaquil. Vigencia del 8 de Mayo al 8 de junio del 2023 o hasta agotar stock. Premios limitados. Aplican restricciones.
                </p>
            </section>
            {terms ? <Terms setTerms={setTerms} /> : null}
            {showAlert ? <Alert setShowAlert={setShowAlert} /> : null}
        </div>
    );
}

export default Home;