
function Terms({setTerms} : any) {
    return (
        <div className="w-screen h-screen fixed top-0 left-0 z-10 flex justify-center items-center overflow-hidden">
            <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex justify-center items-center overflow-hidden"
                onClick={() => { setTerms(false) }}
            ></div>
            <div className="relative w-5/6 md:w-2/3 h-full max-h-[600px] md:max-h-[500px] bg-white rounded-xl flex flex-col justify-center items-center overflow-hidden">
                <div className="overflow-y-scroll my-5 md:my-10 md:mx-5 p-5 italic text-[#666666]">
                    <h3 className="text-2xl md:text-3xl text-[#1E1E1E] font-bold">Términos y Condiciones <br /> “El Equipo Perfecto” </h3> <br /> <br />
                    <p>
                        Las siguientes normas, términos y condiciones de uso, se aplicarán para tu participación en esta promoción.<br /> <br />

                        La participación en esta promoción implica el conocimiento de las reglas establecidas por los Organizadores en el presente documento de “Términos y Condiciones”, sometidos a la legislación ecuatoriana, mismos que serán colgados en el portal web http://tuequipo.mundopepsi.com/.<br /> <br />

                        Los Organizadores se reservan el derecho de interpretar, completar o modificar las reglas determinadas en este documento en cualquier momento. <br /> <br />
                    </p>
                    <p>
                        <b>I. ORGANIZADOR</b> <br />
                        THE TESALIA SPRINGS COMPANY S.A y PEPSICO ALIMENTOS ECUADOR CÍA.LTDA  (en adelante los “Organizadores”) organizan la promoción “El equipo Perfecto” (en adelante el “Concurso”), el cual tendrá vigencia desde el 1 de mayo al 8 de Junio de 2023 dentro del territorio ecuatoriano. <br /> <br />
                    </p>
                    <p>
                        <b>II. VIGENCIA Y TERRITORIO</b> <br />
                        En primer lugar, debes saber que los datos personales que proporciones oportunamente a PepsiCo podrán aplicarse a todas las marcas y productos de PepsiCo, sin distinción, en virtud de la presente Política y de la legislación aplicable.<br /> <br />
                    </p>
                    <p>
                        <b>III. MECÁNICA</b> <br />
                        En el Concurso podrán participar aquellas personas físicas que residan en el Territorio la República del Ecuador y sean mayores de 18 (dieciocho) años. No podrán participar del Concurso: el personal de la organización, ni sus parientes hasta el primer grado de consanguinidad y segundo de afinidad. Tampoco, los empleados o familiares hasta el segundo grado de consanguinidad y segundo de afinidad de los ALMACENES, agentes impresores o de cualquier otro patrocinador, sus cónyuges, sus parientes por consanguinidad o afinidad hasta el segundo grado inclusive. El ganador deberá́ probar su elegibilidad. Esta es una promoción válida para personas mayores a 18 años. Para empezar a participar, el consumidor deberá realizar los siguientes pasos:<br /> <br />
                        <ol className="list-decimal ml-10">
                            <li>Comprar los productos participantes de las marcas Pepsi, Doritos o Ruffles en los almacenes participantes. </li>
                            <li>Escanea el código QR del empaque, ingresar los datos y sube una foto de tu factura. </li>
                            <li>Participa y gana 2 entradas para disfrutar la gran final de la UEFA CHAMPIONS LEAGUE. </li>
                        </ol> <br />
                        Los Organizadores anunciarán los ganadores el viernes el 9 de junio del 2023. <br /> <br />
                    </p>
                    <p>
                        <b>II. PREMIOS</b> <br /> <br />
                        <ol className="list-decimal ml-10">
                            <li>Todos los participantes que cumplan con los pasos anteriores entrarán al sorteo que se realizará a través de <a href="https://app-sorteos.com/es/apps/sorteados">https://app-sorteos.com/es/apps/sorteados</a> </li>
                            <li>La persona ganadora será escogida mediante un sorteo que se realizará en la siguiente web: <a href="https://app-sorteos.com/es/apps/sorteados">https://app-sorteos.com/es/apps/sorteados</a> <br /> <br />
                                <b>Premios principales</b> <br /> <br />
                                El premio para cada uno de los ganadores consiste en disfrutar la final de la UEFA Champions League en el Pepsi House el día sábado 10 de Junio de 2023.<br /> <br />

                                Cada uno de los premios comprende únicamente lo especificado en estos Términos y Condiciones.<br /> <br />

                                Los Organizadores se reservan, en caso de obligada necesidad, el derecho de sustituir el premio por otro de similares características.<br /> <br />

                                Los ganadores serán notificados a través del correo electrónico y número de celular que registraron en la página web http://tuequipo.mundopepsi.com/.<br /> <br />

                                En caso de que los Organizadores no hayan podido comunicarse con los ganadores en un plazo de 24 horas el premio será entregado al siguiente participante seleccionado durante el sorteo.<br /> <br />

                                Los Organizadores se reservan el derecho de validar y verificar la identidad de los ganadores, por los medios que considere pertinentes, para determinar si entra en las restricciones determinadas en la cláusula V para hacerse acreedor al premio según lo definido en este documento. Se requerirá además que los datos registrados en la página coincidan con los que se verificarán telefónicamente al momento de ser informado como ganador del concurso. El ganador deberá presentar el original de la cédula de identidad. De no cumplir con estos requisitos, el premio no podrá ser entregado. Los participantes que cumplan con estas condiciones estarán habilitados para recibir su premio.
                                <br /> <br />
                            </li>
                        </ol> <br />
                    </p>
                    <p>
                        <b>V. RESPONSABILIDADES Y RESTRICCIONES</b> <br />
                        Los ganadores del Concurso deberán sujetarse a las siguientes condiciones, responsabilidades y restricciones: <br />
                        <ol className="list-decimal ml-10">
                            <li>El premio debe ser reclamado única y exclusivamente por la persona que registró su información en la página.</li>
                            <li>El premio no es reembolsable, es decir no se dará a cambio dinero en efectivo equivalente al premio.</li>
                            <li>La responsabilidad de los Organizadores se limita a la entrega de los premios ofrecidos en estos Términos y Condiciones, cualquier situación posterior a la entrega de estos no será responsabilidad de los Organizadores.</li>
                            <li>Los Organizadores podrán suspender en forma inmediata la promoción, sin asumir responsabilidad alguna, si se llega a detectar cualquier tipo de fraude o irregularidad. De ser el caso, esta circunstancia se comunicará públicamente, fecha a partir de la cual queda sin efecto y se dará por terminado el Concurso.</li>
                            <li>No podrán resultar beneficiados con este Concurso los empleados directos, distribuidores o subdistribuidores que comercializan los productos de los organizadores en todos los canales de ventas, personal de los Operadores Logísticos que se encargan de la entrega del producto hasta el tercer grado de consanguinidad y tercer grado de afinidad, así como tampoco cualquier persona relacionada con las empresas THE TESALIA SPRINGS COMPANY S.A. y PEPSICO ALIMENTOS ECUADOR CÍA.LTDA.</li>
                            <li>Solo mayores de edad podrán concursar y hacerse acreedores de los premios. Se permitirá que personas que cumplan 18 años antes del 5 de abril de 2022 puedan reclamar el premio.</li>
                            <li>Los participantes deben residir en Ecuador y tanto la entrega de los premios, como el canje de estos se harán únicamente dentro del territorio ecuatoriano.</li>
                            <li>Los datos de contacto que se proporcionen serán utilizados exclusivamente para su verificación, entrega de premios, confirmación del recibo de premios en el caso de haberlo ganado y/o en caso de haber solicitado cualquier información adicional.</li>
                        </ol> <br />
                    </p>
                    <p>
                        <b>VI. AUTORIZACIONES Y DERECHOS</b> <br />
                        Los participantes otorgan su consentimiento libre, explícito, inequívoco e informado para que los Organizadores traten sus datos personales y los almacenen en una base de datos que será utilizada para promocionar el lanzamiento de nuevos productos o actividades de mercadeo de PEPSICO ALIMENTOS ECUADOR CÍA.LTDA y de The Tesalia Springs Company S.A. El participante cede su información para la base de datos.<br /> <br />

                        Los ganadores autorizan expresamente para que se utilicen los datos personales por cualquier medio o forma, ya sea física o inalámbrica, y además el solo hecho que una persona cobre un premio de este Concurso, habilitará a PEPSICO ALIMENTOS ECUADOR CÍA.LTDA y The Tesalia Springs Company S.A para divulgar su imagen y su nombre como participante en la forma en que la compañía estime conveniente.<br /> <br />

                        Así mismo, todos los participantes y ganadores autorizan a los Organizadores a tratar sus datos personales para cumplir sus obligaciones y compromisos con en este Concurso, quedando autorizados a almacenarlos, transferirlos, nacional o internacionalmente, cederlos o compartirlos sin previa autorización por escrito de parte de los participantes y ganadores. Estos datos incluyen datos de categorías especiales como datos de salud y datos crediticios, en donde los participantes y ganadores otorgan su consentimiento expreso para que sean tratados. Estos datos serán almacenados, por lo que los Organizadores declaran sobre la existencia de una base de datos y que esta está resguardada debidamente bajo las medidas de seguridad adecuadas para proteger los datos personales entregados. <br /> <br />

                        Los Organizadores informarán acerca de violaciones a las seguridades implementadas para el tratamiento de datos personales. Los participantes y ganadores tendrán derecho revocar su consentimiento y a ejercer todos los derechos consagrados en la Ley Orgánica de Protección de Datos Personales. Pasados diez (10) años desde la obtención de los datos personales, Los Organizadores se comprometen a eliminar los datos personales de manera segura y sistémica, sin quedarse con ningún dato a su favor para ningún fin específico. <br /> <br />

                        Los participantes y ganadores declaran que los datos personales entregados no son erróneos o inexactos, pero en caso de serlos, podrán ser corregidos, caso contrario no continuarán como parte del Concurso. Los ganadores y participantes están garantizados de la existencia y forma en que pueden hacerse efectivos sus derechos de acceso, eliminación, rectificación y actualización, oposición, anulación, portabilidad, limitación del tratamiento y a no ser objeto de una decisión basada únicamente en valoraciones automatizadas, así como la forma de realizar reclamos ante el responsable del tratamiento de datos y la Autoridad de Protección de Datos Personales.<br /> <br />

                        El participante y ganadores declaran que no tienen obligaciones ni derechos de exclusividad con personas naturales o jurídicas que limiten el ejercicio de estas condiciones por lo que eximen de cualquier responsabilidad o reclamo de cualquier tercero por este hecho.<br /> <br />

                        Además, cada participante autoriza que su nombre pueda ser publicado en medios de comunicación de los Organizadores.<br /> <br />
                    </p>
                    <p>
                        <b>VII. PROPIEDAD INTELECTUAL</b> <br />
                        Los elementos (fotos, diseños, logos, etc.) de los Organizadores no pueden ser reproducidos, usados, adaptados o comercializados sin la aprobación escrita de los Organizadores, por lo que la participación en este evento no implica una autorización de uso de sus derechos de propiedad intelectual ni de terceros, así como derechos de imagen. <br /> <br />
                    </p>
                    <p>
                        <b>VIII. MODIFICACIONES DE LOS TÉRMINOS Y CONDICIONES </b> <br />
                        Los Organizadores se reservan el derecho, a su exclusiva discreción, de modificar, alterar, agregar o eliminar partes de estos Términos y Condiciones en cualquier momento y sin previo aviso.<br /> <br />

                        En caso de controversias por estas condiciones las partes se someten a los juzgados de lo civil de la ciudad de Guayaquil.
                        <br /> <br />
                    </p>
                    <p>
                        <b>IX. INTERPRETACIÓN</b> <br />
                        Al Concurso le serán aplicables y se respetarán todas las leyes y normas aplicables a las actividades promocionales y los lineamientos y reglamentaciones fijados por las autoridades locales competentes. Por el hecho de participar, los Participantes reconocen estar sujetos a las Reglas del Concurso y a las decisiones de los Organizadores, que serán inapelables y vinculantes en todo sentido. Los Organizadores determinarán el criterio a aplicar en cualquier situación no prevista en estos términos y condiciones, siendo la única autoridad interpretativa de las mismas, reservándose el derecho a efectuar cualquier tipo de modificación respecto de aquellas situaciones, siempre y cuando no se altere la esencia del Concurso y de acuerdo con la normatividad aplicable. <br /> <br />
                    </p>
                    <p>
                        <b>X. RESPONSABILIDAD</b> <br />
                        Los Organizadores no podrán ser considerados responsables por ningún daño o perjuicio ocasionado o que pudiera ocasionarse a los participantes y/o a los ganadores y/o a cualquier tercero directa o indirectamente por la realización de este Concurso, en sus personas y/o bienes, debido a o con ocasión de la utilización del Premio que en este Concurso se ofrece. La responsabilidad de los Organizadores finalizará al poner a disposición de los ganadores los Premios a los que se hicieron acreedores.<br /> <br />

                        Por el hecho de participar en este Concurso o aceptar cualquier Premio que puedan ganar, los participantes aceptan eximir a los Organizadores, sus empresas matrices, filiales y subsidiarias,<br /> <br />

                        unidades y divisiones y agencias de publicidad y promoción y proveedores del Premio, a sus respectivos funcionarios, directores, agentes, representantes y empleados, y a los sucesores, representantes y cesionarios respectivos de estas empresas e individuos de cualquier procedimiento judicial o extrajudicial, reclamo perjuicio, pérdida o daño que pueda producirse, directa o indirectamente, por participar en este Concurso o aceptar o usar el Premio.<br /> <br />

                        Así mismo, los Organizadores, sus agentes, empleados, compañías afiliadas, no serán responsables por cualquier daño o inconveniente sufridos por alguna(s) persona(s) como resultado de su participación en el Concurso o por el Premio que reciba. Los Organizadores no serán responsable de ningún daño o pérdida ocasionada a los participantes de este Concurso, o a terceros, en sus personas y/o bienes.
                        <br /> <br />
                    </p>

                    <p>
                        <b>XI. JURISDICCIÓN</b> <br />
                        Toda relación que en virtud de esta Actividad se genere entre los Participantes y el Organizador será regida y concertada en total sujeción a las leyes de la República de Ecuador, renunciando los participantes a cualquier otra ley a cuya aplicación pudiere tener derecho. Así mismo, resultarán competentes para dirimir cualquier controversia o procedimiento legal que surja entre los Organizadores y los participantes, los jueces de la ciudad de Guayaquil, cualquier sea la causa o el reclamo, con renuncia expresa por parte del Participante a cualquier otra jurisdicción que pudiera corresponder.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Terms;