import check from '../assets/img/check.svg'

function Alert({setShowAlert} : any) {
    return (
        <div className="w-screen h-screen fixed top-0 left-0 z-10 flex justify-center items-center overflow-hidden">
            <div className="absolute top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex justify-center items-center overflow-hidden"
                onClick={() => { setShowAlert(false) }}
            ></div>
            <div className="relative w-5/6 md:w-2/5 h-full max-h-[500px] md:max-h-[250px] bg-[#004A98] rounded-xl flex flex-col justify-center items-center text-white overflow-hidden p-10 font-bold italic">
                <img className='w-20 md:w-14' src={check} alt="check" />
                <h3 className="my-10 md:mt-5 md:mb-2 text-3xl text-center">TU REGISTRO FUE EXITOSO</h3>
                <p className="text-lg text-center">GRACIAS POR PARTICIPAR</p>
            </div>
        </div>
    )
}

export default Alert;