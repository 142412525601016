import * as globals from '../utils/globals';

const myHeaders = new Headers();
// myHeaders.append("Authorization", globals.TOKEN_API);
myHeaders.append("Content-Type", "application/json");

export async function register(email, full_name, ci, phone, file_name, file) {
    let raw = JSON.stringify({
        "email": email,
        "full_name": full_name,
        "ci": ci,
        "phone": phone,
        "file_name": file_name,
        "file": file
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
        mode: 'no-cors',
        headers: {
            'Origin': '*'
        }
    };

    // console.log("--====")
    // console.log(globals.URL_CLOUDFRONT)
    const response = await fetch(`${globals.URL_CLOUDFRONT}contact/register`, requestOptions);
    console.log("=======")
    console.log(response)
    // console.log("----> ", response)
    // const data = await response.json();
    return response
}